import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export interface Props {
  children: JSX.Element;
  renderIn?: number;
  rootMargin?: string;
}

export default function LazyRender({
  children,
  renderIn,
  rootMargin,
}: Props): JSX.Element {
  const [renderInPassed, setRenderInPassed] = useState(false);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
    rootMargin,
  });

  useEffect(() => {
    let timeoutId;

    if (typeof renderIn !== "undefined") {
      timeoutId = setTimeout(() => {
        setRenderInPassed(true);
        timeoutId = void 0;
      }, renderIn);
    }

    return () => {
      if (timeoutId !== void 0) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  if (inView || renderInPassed) {
    return children;
  } else {
    return <div ref={ref}></div>;
  }
}
