import { makeStyles } from "@lib/themes";
import { ITheme } from "@lib/themes/types";

const useStyles = makeStyles((theme: ITheme, props) => ({
  titleGrid: {
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(4.5),
      flexWrap: "nowrap",

      ".pickerBox": {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  title: {
    marginBottom: theme.spacing(3),

    fontSize: props.changeVariant && "2.5rem",

    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(3),

    fontSize: props.changeVariant && "1rem",
    fontWeight: props.changeVariant && 400,

    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  optionBox: {
    display: "flex",
    marginLeft: 0,

    [theme.breakpoints.only("md")]: {
      alignItems: "flex-end",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
      marginLeft: theme.spacing(5),
    },
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  margin: {
    marginRight: 0,

    [theme.breakpoints.only("md")]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(3),
    },
  },
}));

export { useStyles };
