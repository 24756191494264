import { useEffect, useState } from "react";
import {
  getValueForKeyInBrowserStorage,
  storeInBrowserStorage,
} from "@lib/utils/browser-storage";

export const useStorageChecker = (interval, currency, disabled = false) => {
  const [activeSelects, setActiveSelects] = useState({
    interval,
    currency,
  });

  useEffect(() => {
    if (!disabled && getValueForKeyInBrowserStorage("titleOptions")) {
      setActiveSelects(getValueForKeyInBrowserStorage("titleOptions"));
    }
  }, [disabled]);

  useEffect(() => {
    if (disabled) {
      storeInBrowserStorage("titleOptions", { interval, currency });
    }
  }, [disabled, interval, currency]);

  return {
    activeSelects,
  };
};
