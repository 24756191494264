export interface Interval {
  title: string;
  slug: string;
  value: string;
}

export interface Currency {
  title: string;
  slug: string;
  value: string;
}

export interface JsonMap {
  [member: string]:
    | string
    | number
    | boolean
    | null
    | JsonArray
    | JsonMap
    | Date;
}

export type JsonArray = Array<
  string | number | boolean | null | JsonArray | JsonMap | Date
>;

export type Json =
  | JsonMap
  | JsonArray
  | string
  | number
  | boolean
  | null
  | Date;

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface SortItem<TColumns extends string> {
  column: TColumns;
  order: SortOrder;
}
