import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useScrollPosition } from "@lib/hooks/useScrollPosition";
import { useRouter } from "next/router";

export interface PageDescriptionProps {
  children: React.ReactNode;
  windowScrollTop?: number;
}

const PageDescription = ({
  children,
  windowScrollTop = 300,
}: PageDescriptionProps) => {
  const router = useRouter();
  const { scrollPosition } = useScrollPosition();
  const [visibility, setVisibility] = useState(true);
  const [page, setPage] = useState(null);

  useEffect(() => {
    if (
      router.query.page &&
      router.query.page != page &&
      scrollPosition < windowScrollTop
    ) {
      setPage(router.query.page);
      setVisibility(true);
    }
  }, [router, scrollPosition]);

  useEffect(() => {
    if (scrollPosition > windowScrollTop && visibility) {
      setVisibility(false);
    }
  }, [scrollPosition, visibility]);

  return visibility && <Box>{children}</Box>;
};

export default PageDescription;
