import { Box, Grid, Typography } from "@mui/material";

import Picker from "@components/Picker";
import Breadcrumbs from "@components/Breadcrumbs";
import { DEFAULT_INTERVALS_ARRAY, DEFAULT_CURRENCIES_ARRAY } from "@lib/const";

import { useStyles } from "./styles";
import { TitleOptionsProps } from "./types";
import React, { useEffect, useState } from "react";
import { useStorageChecker } from "@lib/hooks/useStorageChecker";
import PageDescription from "@components/PageDescription";
import { useRouter } from "next/router";

const paths = ["/", "/assets"];

function TitleOptions({
  titleText,
  subtitleText,
  setCurrency,
  setInterval,
  breadcrumbs,
  selectedInterval,
  selectedCurrency,
  useIntervals = true,
  intervalOptions = DEFAULT_INTERVALS_ARRAY,
  currencyOptions = DEFAULT_CURRENCIES_ARRAY,
  showPicker = true,
  titleContent,
  titleTextContent,
  gaEventCategory,
  homePage = false,
}: TitleOptionsProps) {
  const router = useRouter();
  const [changeVariant, setChangeVariant] = useState(false);
  const classes = useStyles({
    changeVariant,
  });
  const { activeSelects } = useStorageChecker(
    selectedInterval,
    selectedCurrency,
    homePage,
  );

  useEffect(() => {
    if (homePage) {
      setCurrency(currencyOptions[0]);
      setInterval(intervalOptions[0]);
    } else {
      setCurrency(activeSelects.currency);
      setInterval(activeSelects.interval);
    }
  }, [homePage, activeSelects]);

  useEffect(() => {
    if (paths.some((path) => path === router.pathname)) {
      if (!changeVariant) {
        setChangeVariant(true);
      }
    } else {
      if (changeVariant) {
        setChangeVariant(false);
      }
    }
  }, [router, changeVariant]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={`title-options ${classes.titleGrid}`}>
      <Grid item container xs={12}>
        {titleText && (
          <Box>
            <Typography
              variant={changeVariant ? "h1" : "h2"}
              color="textPrimary"
              className={`title-options__title ${classes.title}`}>
              {titleText}
              {titleTextContent && titleTextContent(selectedCurrency)}
            </Typography>
            {subtitleText && (
              <PageDescription windowScrollTop={300}>
                <Typography
                  variant={changeVariant ? "h2" : "body2"}
                  color="textPrimary"
                  className={classes.subtitle}>
                  {subtitleText}
                </Typography>
              </PageDescription>
            )}
          </Box>
        )}
        {!titleText && breadcrumbs && (
          <Box className={classes.title}>
            <Breadcrumbs data={breadcrumbs} />
          </Box>
        )}
        {titleContent && titleContent}
      </Grid>
      {showPicker && (
        <Grid item xs={12} className={classes.optionBox}>
          {useIntervals && (
            <Box className={classes.margin}>
              <Picker
                disabled={homePage}
                value={selectedInterval}
                values={intervalOptions}
                setValue={setInterval}
                gaEventCategory={gaEventCategory}
              />
            </Box>
          )}
          <Box>
            <Picker
              disabled={homePage}
              value={selectedCurrency}
              values={currencyOptions}
              setValue={setCurrency}
              withoutText
              gaEventCategory={gaEventCategory}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export { TitleOptions };
