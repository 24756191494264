import { defaultCardImageUrl } from "@lib/seo";
import Head from "next/head";
import { useRouter } from "next/router";

export interface AppHeadProps {
  title?: string;
  description?: string;
  previewImage?: string;
  children?: React.ReactNode;
}

export const AppHead = ({
  title = "",
  description = "",
  previewImage = "",
  children,
}: AppHeadProps) => {
  const app_id = "307589224266692";
  const router = useRouter();
  const currentURL = `${process.env.WEBSITE}${router.asPath}`;
  const image = previewImage === "" ? defaultCardImageUrl : previewImage;
  return (
    <Head>
      {children}

      {/* Open Graph */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={currentURL} key="ogurl" />
      <meta property="og:image" content={image} key="ogimage" />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:description" content={description} key="ogdesc" />
      <meta property="og:app_id" content={app_id} key="ogapp_id" />

      {/* Twitter */}
      {/* Twitter card will read og:title, og:image, og:description */}
      <meta name="twitter:card" content="summary" key="twcard" />
    </Head>
  );
};
