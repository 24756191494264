import React, { Fragment, useMemo } from "react";
import {
  Typography,
  Box,
  TableCell,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { format } from "d3-format";

import { makeStyles } from "@lib/themes";
import NumberDynamic from "@components/NumberDynamic";
import SkeletonImg from "@components/SkeletonImg";
import TablePage, {
  StaticPageProps,
  getDefaultGetTitles,
} from "@pages-components/TablePage";
import { getAssets } from "@server-lib/crons/coincap/assets";
import { useMediaStyles } from "@lib/hooks/styles";
import { ITheme } from "@lib/themes/types";
import withAppProps from "@server-lib/with-app-props";
import NumberFormat from "@components/NumberFormat";
import { ASSETS_SEO } from "@lib/seo";
import { AppHead } from "@components/AppHead";
import { homeTitles, SortColumns } from "@pages-components/assets";
import InfoBlock from "@components/InfoBlock";
import trackEvent, { GAEventCategory } from "@lib/ga";
import { CoinCapAssetExtended } from "@server-lib/crons/coincap/types";
import { JsonArray } from "@lib/types";

const useStyles = makeStyles((theme: ITheme) => ({
  blur: {
    filter: "blur(2px)",
  },
  wrapper: {
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      marginTop: "28px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down("md")]: {
      ".title-options": {
        marginBottom: theme.spacing(5),
      },
    },
  },
  descriptionBg: {
    position: "absolute",
    left: 0,
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center top",
    [theme.breakpoints.down("sm")]: {
      top: 84,
      backgroundImage: "url(../home-bg-mobile.png)",
      backgroundSize: "554px 264px",
      height: 264,
    },
    [theme.breakpoints.up("sm")]: {
      top: 0,
      backgroundImage: "url(../home-bg.png)",
      backgroundSize: "1440px 444px",
      height: 442,
    },
  },
  description: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(15),
    },
    "& + div": {
      position: "relative",
    },
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  subHeader: {
    maxWidth: 770,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  infoColExpanded: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      flex: "50% 0 0",
      "&:nth-last-child(-n+2)": {
        marginBottom: 0,
      },
    },
    [theme.breakpoints.up("sm")]: {
      flex: "33.33333% 0 0",
      "&:nth-last-child(-n+3)": {
        marginBottom: 0,
      },
    },
  },
  infoCol: {
    marginBottom: theme.spacing(2),
    flex: "33.33333%",
  },
  tabs: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 6,
    },
  },
  category: {
    padding: "4px 12px",
    display: "flex",
    borderRadius: 6,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.picker.backgroundHover,
    },
    color: theme.palette.picker.text,
  },
  logo: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

export default function AssetsOverview(
  props: StaticPageProps<CoinCapAssetExtended>,
) {
  const theme = useTheme();
  const mediaClasses = useMediaStyles(theme);
  const isDownSm: boolean = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const getTitles = useMemo(() => {
    const activeCategory = Number(1); // spot
    return getDefaultGetTitles(homeTitles, activeCategory);
  }, []);

  const renderStickyHeaderContent = () => (
    <img src="/logo.png" className={classes.logo} height="40" width="116" />
  );

  return (
    <>
      <AppHead
        title={ASSETS_SEO.title}
        description={ASSETS_SEO.secondDescription}
        previewImage={""}>
        <title>{ASSETS_SEO.title}</title>
        <meta name="description" content={ASSETS_SEO.description} />
      </AppHead>
      <div className={classes.wrapper}>
        <Box className={classes.descriptionBg} />
        <Box className={classes.description} textAlign="center">
          <Typography
            variant="h1"
            color="textPrimary"
            className={classes.header}>
            Data Driven Decisions for Crypto Holders
          </Typography>
          <Typography
            component="p"
            variant="h4"
            color="textPrimary"
            className={classes.subHeader}>
            Make informed decisions about your crypto holdings using enhanced
            data from the top exchanges. Access real-time data, historical data,
            liquidity charts, comparison and more.
          </Typography>
        </Box>

        <TablePage<CoinCapAssetExtended, SortColumns>
          {...props}
          filterBy="volumeUsd24Hr"
          pageTitle=""
          pageSubtitle=""
          api="/api/assets/overview"
          getTitles={getTitles}
          rowKey="name"
          useIntervals
          isFixedLayout={props.dataCount > props.pageSize}
          serverSideSorting={false}
          homePage
          rowContent={(row, { index, currency }) => {
            return (
              <Fragment>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={() =>
                      trackEvent({
                        action: `Asset ${row.name}`,
                        category: GAEventCategory.HomePage,
                        label: `Asset ${row.name}`,
                      })
                    }>
                    <Box mr={1} height={24} width={24}>
                      <SkeletonImg
                        width={24}
                        height={24}
                        src={row.img_url}
                        skeletonProps={{ variant: "circular" }}
                        alt={`${row.name} (${row.symbol}) logo`}
                      />
                    </Box>
                    <Typography
                      variant="body1"
                      noWrap
                      overflow="hidde"
                      textOverflow="ellipsis"
                      maxWidth="100%">
                      {row.name}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                        pl={0.5}>
                        ({row.symbol})
                      </Typography>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <NumberFormat
                    value={row.priceUsd}
                    format=",.5~r"
                    currency={currency.value}
                    changeAnimationEnabled={true}
                  />
                </TableCell>
                <TableCell>
                  <NumberFormat
                    dynamic={Number(row.changePercent24Hr)}
                    value={row.changePercent24Hr}
                    format="percent"
                  />
                </TableCell>
                <TableCell>
                  <NumberFormat
                    value={row.marketCapUsd}
                    currency={currency.slug}
                    format="bigNumber"
                  />
                </TableCell>
                <TableCell>
                  <NumberFormat
                    value={row.volumeUsd24Hr}
                    currency={currency.slug}
                    format={isDownSm ? ",d" : "bigNumber"}
                    shrinkForMobile={false}
                  />
                </TableCell>
                <TableCell>
                  <NumberDynamic
                    boxProps={{
                      paddingRight: "24px",
                    }}
                    dynamic={Math.sign(row.volumeChangePercent24Hr)}
                    typographyProps={isDownSm && { variant: "body2" }}>
                    {format(",.2f")(Math.abs(row.volumeChangePercent24Hr))}%
                  </NumberDynamic>
                </TableCell>
              </Fragment>
            );
          }}
          mobileItem={(row, { index, currency }) => {
            return (
              <>
                <Box display="flex" alignItems="center" minHeight={40} mb={1}>
                  <Box mr={1} display="flex">
                    <Typography color="textSecondary" variant="body2">
                      #{index + 1}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={() =>
                      trackEvent(
                        {
                          action: `Asset ${row.name}`,
                          category: GAEventCategory.HomePage,
                          label: `Asset ${row.name}`,
                        },
                        isDownSm,
                      )
                    }>
                    <Box mr={1} height={24} width={24}>
                      <SkeletonImg
                        width={24}
                        height={24}
                        src={row.img_url}
                        skeletonProps={{ variant: "circular" }}
                        alt={`${row.name} (${row.symbol}) logo`}
                      />
                    </Box>
                    <Typography
                      variant="body1"
                      className={mediaClasses.displayOnMobile}>
                      {row.name}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexWrap="wrap">
                  <InfoBlock
                    title="Price"
                    value={
                      <Typography variant="body2">
                        <NumberFormat
                          value={row.priceUsd}
                          format=",.5~r"
                          currency={currency.value}
                          changeAnimationEnabled={true}
                        />
                      </Typography>
                    }
                    display="flex"
                    flexDirection="column"
                    className={classes.infoColExpanded}
                    titleVariant="caption"
                  />
                  <InfoBlock
                    title="Price Change"
                    value={
                      <NumberFormat
                        dynamic={Number(row.changePercent24Hr)}
                        value={row.changePercent24Hr}
                        format="percent"
                      />
                    }
                    display="flex"
                    flexDirection="column"
                    className={classes.infoColExpanded}
                    titleVariant="caption"
                  />
                  <InfoBlock
                    title="Volume"
                    value={
                      <Typography variant="body2">
                        <NumberFormat
                          value={row.volumeUsd24Hr}
                          currency={currency.slug}
                          format={isDownSm ? ",d" : "bigNumber"}
                          shrinkForMobile={false}
                        />
                      </Typography>
                    }
                    display="flex"
                    flexDirection="column"
                    className={classes.infoColExpanded}
                    titleVariant="caption"
                  />
                  <NumberDynamic
                    boxProps={{
                      paddingRight: "24px",
                    }}
                    dynamic={Math.sign(row.volumeChangePercent24Hr)}
                    typographyProps={isDownSm && { variant: "body2" }}>
                    {format(",.2f")(Math.abs(row.volumeChangePercent24Hr))}%
                  </NumberDynamic>
                  <InfoBlock
                    title="Market Cap"
                    value={
                      <Typography variant="body2">
                        <NumberFormat
                          value={row.marketCapUsd}
                          currency={currency.slug}
                          format=",d"
                          shrinkForMobile={false}
                        />
                      </Typography>
                    }
                    display="flex"
                    flexDirection="column"
                    className={classes.infoColExpanded}
                    titleVariant="caption"
                  />
                </Box>
              </>
            );
          }}
          mobileBreakpoint="lg"
          tableContainerClassName={classes.assetsTable}
          showStickyHeader
          stickyHeaderContent={renderStickyHeaderContent}
          gaEventCategory={GAEventCategory.HomePage}
        />
      </div>
    </>
  );
}

export const getStaticProps = withAppProps(async () => {
  const data = await getAssets();

  return {
    props: {
      initialData: (data as unknown) as JsonArray,
    },
    revalidate: 60 * 5,
  };
});
