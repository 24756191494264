export const ASSETS_SEO = Object.freeze({
  title: "Luner: Cryptocurrency real-time prices, liquidity and charts",
  description:
    "Luner: Cryptocurrency real-time prices, data, supply, trading volume, market cap, charts, historical data, liquidity, and all-time highs for countless cryptocurrencies",
  secondDescription:
    "Luner: Disover real-time data points for countless cryptocurrencies including prices, data, supply, trading volume, market cap, liquidity and much more",
});

export const ASSET_SEO = Object.freeze({
  overview: {
    getTitle(name: string, symbol: string, price?: string) {
      return `Luner: ${symbol} ${
        price ? `${price} ` : ""
      }, Cap, Charts, Volume and Supply`;
    },
    getDescription(name: string, symbol: string) {
      return `Get ${name} / ${symbol} price, real-time data, market cap, historical data, charts, supply, trading volume, pairs, liquidity, exchanges and more information`;
    },
    getSecondDescription(name: string, symbol: string) {
      return `Use Luner to get ${name} / ${symbol} price, real-time data, market cap, historical data, charts, supply, trading volume, pairs, liquidity, exchanges and more info`;
    },
  },
  spotTrading: {
    getTitle(price?: string, coin?: string) {
      return `Luner: ${coin} ${
        price ? `${price}` : ""
      } ranked by spot trading opportunity`;
    },
    getDescription(price?: string, coin?: string) {
      return `View ${coin} ${
        price ? `${price}` : ""
      } ranked by the best spot trading opportunity, as well as base, quote, spot price, volume, number of trades, Orderbook, MDR and much more`;
    },
  },
  liquidity: {
    getTitle(title: string) {
      return `Luner: View exchanges ranked by ${title} liquidity`;
    },
    getDescription(title: string) {
      return `Luner: use Luner to view various exchanges ranked by ${title} liquidity as well as volume, number of pairs, asks orderbook depth, asks change and much more`;
    },
  },
});

export const EXCHANGES_SEO = Object.freeze({
  title: "Cryptocurrency Exchanges, Price, Liquidity and Charts",
  description:
    "Cryptocurrency Exchanges- Search our cryptocrrency exchanges ranked by volume, liquidity, orderbook depth, no. of trades, avg. trade and many more data points",
});

export const EXCHANGE_SEO = Object.freeze({
  overview: {
    getTitle(name: string) {
      return `Luner: ${name} overview and statistics and trading volume`;
    },
    secondTitle: "Luner: use Luner to explore various cryptocurrency exchanges",
    getDescription(name: string) {
      return `Use Luner to view ${name} by exchange statistics and discover insights from data such as volume distribution by pair, volume distribution by asset and much more`;
    },
  },
  statistics: {
    getTitle(name: string) {
      return `Luner: ${name} and other exchanges ranked by statistics`;
    },
    secondTitle: "Luner: View Cryptocurrency Exchanges Ranked By Statistics",
    getDescription(name: string) {
      return `Use Luner to view ${name} by exchange statistics and discover insights from data such as volume distribution by pair, volume distribution by asset and much more`;
    },
  },
  spotTrading: {
    getTitle(name: string) {
      return `Luner: ${name} and other crypto Exchanges by Spot Trading`;
    },
    secondTitle: "Luner: View Various Cryptocurrency Exchanges by Spot Trading",
    getDescription(name: string) {
      return `View ${name} as well as other cryptocurrency exchanges ranked by spot trading potential, based on data such as spot price, total orderbook, MDR and much more`;
    },
  },
});

export const ICOS_IEOS_SEO = Object.freeze({
  title: "Luner: ICO and IEO Ranking By Volume, ROI, Amount Raised and more",
  description:
    "Search our cryptocurrency tokens Initial Coin Offering and Initial Exchange Offering, Get ICO price, amount raised, as well as performance and ROI over time",
});

export const DEFI_SEO = Object.freeze({
  title: "Luner: DeFi Total Value Locked over time with live prices",
  description:
    "Luner: Search our cryptocurrency decentralised finance tokens ranked by total value locked and their prices, and also see total value locked change over time",
});

export const LIQUIDITY_SEO = Object.freeze({
  title: "Luner: Cryptocurrency Liquidity and Crypto Market Liquidity",
  description:
    "Use Luner to view cryptocurrency market liquidity analysis in real-time, bids, as well as volume and volatility by different cryptocurrency tokens over time",
});

export const P2P_SEO = Object.freeze({
  title: "Luner: View Global Peer-to-Peer Transactions, in Real-Time",
  description:
    "Discover all the peer to peer bitcoin transactions around the world using the Luner heatmap and discover where bitcoin is exchanged globally all in real-time",
});

export const LABS_SEO = Object.freeze({
  title: "Luner: Discover all our Cryptocurrency Trading Tools",
  description:
    "Luner Trading labs: use our top cryptocurrency trading labs tools to uncover new opportunities and make better trading decisions easier, faster and smarter",
});

export const CRYPTO_LAB_SEO = Object.freeze({
  title: "Luner: CryptoLab - Compare Different Cryptocurrencies",
  description:
    "Luner: Use the comparison lab to compare various cryptocurrencies by different parameters such as liquidity and prioritise them to discover the best investment",
});

export const SIMULATOR_SEO = Object.freeze({
  title: "Luner: Discover Our Cryptocurrency Buy and Sell Simulator",
  description:
    "Luner Simulator: Identify the best basket trades for different cryptocurrencies, accounting for data points such as slippage and liquidity using the simulator",
});

export const CORRELATION_SEO = Object.freeze({
  title: "Luner: Discover Cryptocurrency Correlations in Real-Time",
  description:
    "Luner: Explore the correlation page to discover new insights and the relationships between different cryptocurrency tokens such as BTC/ETH over period of time",
});

export const PORTFOLIO_SEO = Object.freeze({
  title: "Luner: Use Luner to Manage Your Cryptocurrency Portfolio",
  description:
    "Use the Luner portfolio tracker to easily keep track of your cryptocurrency portfolio. Instantly see changes and convert them to USD, Sats, Gwei and more.",
});

export const ALERTS_SEO = Object.freeze({
  title: "Crypto Alerts, Crypto Market Alerts and My Alerts | Luner",
  description:
    "Set custom alerts to receive push notifications about market movements at any time.",
});

export const ACCOUNT_SEO = Object.freeze({
  title: "Account | Luner",
});

export const ARBITRAGE_SEO = Object.freeze({
  title: "Luner: Find Arbitrage Opportunities Across Various Exchanges",
  description:
    "Discover the Luner arbitrage arena and explore new opportunities that arise across 20+ cryptocurrency exchanges all in real time to make even better trades",
});

export const FORGOT_PASSWORD_SEO = Object.freeze({
  title: "Luner Cryptocurrency login to your account | Forgot Password",
  description:
    "Enter your email address to reset your password to login and gain access to our exclusive cryptocurrency tools and services",
});

export const LOGIN_SEO = Object.freeze({
  title: "Luner Cryptocurrency | Log In Page",
  description:
    "Log in to Luner to discover more cryptocurrency tools, insights and make better trades",
});

export const REALTIME_SEO = Object.freeze({
  // title: {
  //   getTitle(
  //     price?: string,
  //     initialPair?: {
  //       exchange: string;
  //       base: string;
  //       quote: string;
  //     },
  //   ) {
  //     return `${price !== "NaN" ? `${price} ` : ""}${initialPair.exchange}.${
  //       initialPair.base
  //     }/${initialPair.quote} | Luner Terminal`;
  //   },
  // },
  title: "Luner: View cryptocurrency markets through real-time streams",
  description:
    "Luner Terminal: Real time trades, candles and liquidity to all top cryptocurrency exchanges, get visibility to all markets based on real time streams",
});

export const RESET_PASSWORD_SEO = Object.freeze({
  title: "Luner Cryptocurrency login to your account | Reset Password",
  description:
    "Enter and reset your password to login and gain access to our exclusive, market leading cryptocurrency tools and services, to make your next trade the best",
});

export const SIGNUP_FINISH_SEO = Object.freeze({
  title: "Luner Cryptocurrency | Finish Creating Your Free Account",
  description:
    "Finish signing up to gain access to Luner to gain access to exclusive, leading cryptocurrency tools and services to improve your trades. Discover Luner today!",
});

export const SIGNUP_SEO = Object.freeze({
  title: "Luner Cryptocurrency | Sign Up To Create A Free Account",
  description:
    "Sign up to allow you to login and gain access to our exclusive cryptocurrency tools, services and the Luner Labs. Discover Luner today!",
  secondDescription:
    "Sign up to gain access to Luner to gain access to exclusive cryptocurrency tools, services and the Luner Labs. Discover Luner today!",
});

export const PRIVACY_SEO = Object.freeze({
  title: "Luner Cryptocurrency | Privacy Policy",
  description:
    "Luner Cryptocurrency: Privacy Policy. Discover our privacy policy and discover more about how and why we gather, store, manage and disclose data",
});

export const TERMS_SERVICE_SEO = Object.freeze({
  title: "Luner Cryptocurrency | Terms of Service",
  description:
    "Luner Cryptocurrency | Terms of Service. Review and understand the Luner Terms of Service",
});

export const DEV_EXCHANGES_SEO = Object.freeze({
  title: "Decentralized Exchanges | Luner",
});

export const DEV_EXCHANGE_PAIR_SEO = Object.freeze({
  getTitle(name: string) {
    return `Luner | Decentralized Cryptocurrency Exchanges - ${name} Pairs`;
  },
  getDescription(name: string) {
    return `Use Luner to find out new insights on various cryptocurrency tokens pairs on ${name}, such as liquidity, volume, volume change and much more information`;
  },
});

export const DEV_EXCHANGE_TOKEN_SEO = Object.freeze({
  getTitle(name: string) {
    return `Luner | Use Luner to discover insight in ${name} Tokens`;
  },
  getDescription(name: string) {
    return `Use Luner to find out and explore new insights on ${name} tokens such as number of pairs, total liquidity, volume change, liqudity change, TX and much more`;
  },
});

export const ASSET_DOMINANCE_SEO = Object.freeze({
  title:
    "Luner: Review and compare market cap dominance of hundreds of crypto assets.",
  description:
    "Review and compare market cap dominance of hundreds cryptocurrency assets, get real-time dominance by asset and historical data",
});

export const defaultCardImageUrl = `${process.env.WEBSITE}/tags/luner_logo.png`;
export const cryptoLabImageUrl = `${process.env.WEBSITE}/tags/cryptoLab.png`;
export const simulatorLabImageUrl = `${process.env.WEBSITE}/tags/simulatorLab.png`;
export const correlationLabImageUrl = `${process.env.WEBSITE}/tags/correlationLab.png`;
export const arbitrageLabImageUrl = `${process.env.WEBSITE}/tags/arbitrageLab.png`;
