import React, { useCallback, useState, useMemo, useEffect } from "react";
import cx from "classnames";
import LazyRender from "@components/LazyRender";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  ButtonBase,
  Box,
  TableContainer as TableCoreContainer,
  Skeleton,
  useMediaQuery,
  useTheme,
  Slide,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { makeStyles } from "@lib/themes";

import IconDown from "@public/down.svg";
import IconUp from "@public/up.svg";
import { ITheme } from "@lib/themes/types";
import useWindowDimensions from "@lib/hooks";
import { PAGE_SIZE } from "@lib/const";
import { SortItem, SortOrder } from "@lib/types";
import PlusIcon from "@public/plus-icon.svg";
import CloseIcon from "@public/close-icon.svg";
import IntervalIcon from "./IntervalIcon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NextLink from "next/link";
import { useRouter } from "next/router";
import MainButton from "@components/MainButton";
import trackEvent, {
  GAEventAction,
  GAEventCategory,
  GAEventLabel,
} from "@lib/ga";

const LAZY_RENDER_BATCH = 20;

export type Title<SortColumns extends string = string> =
  | string
  | {
      title: string;
      sortBy?: SortColumns;
      props?: {
        align?: "inherit" | "left" | "center" | "right" | "justify";
        width?: number | string;
      };
      styles?: {
        padding?: number | string;
      };
      intervalIcon?: string;
      sortAfterFiveSeconds?: boolean;
    };

// interface StyleProps {
//   stickers: number[];
//   pageHeight: number;
//   isFixedLayout: boolean;
//   width: string;
//   topHeader: number;
// }

interface TableContainerProps<TRow, TSortColumns extends string> {
  className?: string;
  data: TRow[];
  dataCount?: number;
  ignoreTableClass?: boolean;
  loading?: boolean;
  withHead?: boolean;
  onChangePage?: (page: number) => void;
  page?: number;
  renderRow?: (
    row: TRow,
    index: number,
    className: string,
    expandedRowValues?: { [key: number]: boolean },
  ) => React.ReactNode;
  rowContent?: (
    row: TRow,
    index: number,
    isExpanded?: boolean,
  ) => React.ReactNode;
  mobileItem?: (
    row: TRow,
    index: number,
    isExpanded?: boolean,
  ) => React.ReactNode | undefined;
  rowKey: string;
  expandedRowValues?: { [key: number]: boolean };
  stickers?: number[];
  titles: Title<TSortColumns>[];
  onChangeSorting?: (sortBy: SortItem<TSortColumns>) => void;
  useRouterPagination?: boolean;
  pageSize?: number;
  serverSideSorting?: boolean;
  isFixedLayout?: boolean;
  width?: string; // like "100%" or "100px"
  topHeader?: number;
  removeBorderSpacing?: boolean;
  homePage?: boolean;
  shouldExpanded?: boolean;
  mobileBreakpoint?: "sm" | "md" | "lg" | "xl";
  isFixedHeader?: boolean;
  showMoreItem?: boolean;
  showMobileSorting?: boolean;
  gaEventCategory?: string;
}

export const useStyles = makeStyles((theme: ITheme, props, css) => {
  const tableBodyRow = {
    height: 64,
    boxShadow: theme.palette.table.rowShadow,
    borderRadius: 8,
    // border: "1px solid #17526B",
    "& > .MuiTableCell-root": {
      border: `1px solid ${theme.palette.table.border}`,
      backgroundColor: theme.palette.table.background,
      borderStyle: "solid none",

      "&:first-child": {
        borderLeftStyle: "solid",
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
      },
      "&:last-child": {
        borderRightStyle: "solid",
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
        overflow: "hidden",
        position: "relative",
      },
      [theme.breakpoints.down("sm")]: {
        // ...getStickersStyles(theme, props.stickers, false),
        padding: theme.spacing(),
      },
      ".arrow-image": {
        width: "10px !important",
        minWidth: "10px !important",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        img: {
          width: "16px !important",
          height: "auto !important",
          maxWidth: "16px !important",
          maxHeight: "16px !important",
          left: "-5px !important",
        },
      },
    },
    "& > .MuiTableCell-stickyHeader": {
      // backgroundColor: theme.palette.rowAlmostBackground.main,
      backgroundColor: "transparent",
    },
    // borderBottom: `1px solid ${theme.palette.divider}`,
  };

  return {
    tableBodyRow,
    gridContainer: {
      position: "relative",
      // overflowX: "auto",
    },
    tableRoot: {
      borderCollapse: !props.removeBorderSpacing && "separate",
      // borderSpacing: "0 8px",
      borderSpacing: props.removeBorderSpacing ? 0 : "0 8px",
      tableLayout: props.isFixedLayout ? "fixed" : "auto",
      width: props.isFixedLayout ? props.width ?? "100%" : "100%",
      [theme.breakpoints.down("md")]: {
        tableLayout: "auto",
        width: "initial",
      },
    },
    tableContainer: {
      // maxHeight: props.pageHeight - 152 - 152 - 32 - 24,
      // [theme.breakpoints.down("sm")]: {
      //   maxHeight: props.pageHeight,
      // },
      "&::-webkit-scrollbar": {
        display: "none",
      },
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      overflowX: "unset",
      [theme.breakpoints.down("lg")]: {
        overflowX: "auto",
      },
    },
    tableHeadRow: {
      height: 56,
      "& th": {
        position: props.isFixedHeader ? "sticky" : "unset",
        textAlign: "left",
      },

      [theme.breakpoints.up("lg")]: {
        "& th": {
          top: `${props.topHeader}px`,
          // background: `${theme.palette.background.default}!important`,
          zIndex: 10,
          "& span": {
            color: theme.palette.text.secondary,
          },
        },
      },
      "& > .MuiTableCell-root": {
        border: 0,
        [theme.breakpoints.down("sm")]: {
          // ...getStickersStyles(theme, props.stickers, true),
          padding: theme.spacing(),
        },
      },
      "& > .MuiTableCell-stickyHeader": {
        backgroundColor: "transparent",
      },
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tableRowPlaceholder: {
      height: 72,
      "& > .MuiTableCell-root": {
        border: 0,
        padding: theme.spacing(),
      },
    },
    headerRow: {
      backgroundColor: "transparent",
      height: 56,
      "& > th:first-child": {
        borderTopLeftRadius: theme.shape.borderRadius,
      },
      "& > th:last-child": {
        borderTopRightRadius: theme.shape.borderRadius,
      },
    },
    tableBody: {
      [`& > .${tableBodyRow}`]: {
        "&:last-child": {
          borderBottom: 0,
        },
      },
      // [`& > .${css(tableBodyRow)}:last-child`]: {
      //   borderBottom: 0,
      //   // "& > td:first-child": {
      //   //   borderBottomLeftRadius: theme.shape.borderRadius,
      //   // },
      //   // "& > td:last-child": {
      //   //   borderBottomRightRadius: theme.shape.borderRadius,
      //   // },
      // },
      [theme.breakpoints.up("md")]: {
        // [`& > .${css(tableBodyRow)}:nth-child(2n)`]: {
        //   borderTop: `1px solid ${theme.palette.divider}`,
        //   backgroundColor: theme.palette.rowAlmostBackground.main,
        // },
      },
    },
    paginationWrap: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      [theme.breakpoints.up("sm")]: {
        "> p": {
          marginLeft: theme.spacing(2),
        },
      },
    },
    pagination: {
      ".MuiButtonBase-root": {
        borderRadius: 8,
        "&:not(.MuiPaginationItem-previousNext)": {
          margin: "0 2px",
          width: 40,
          height: 40,
        },
        "&:not(.Mui-selected):not(.MuiPaginationItem-previousNext):hover": {
          background: theme.palette.pagination.backgroundHover,
        },
        "&.MuiPaginationItem-previousNext": {
          padding: 0,
          margin: 0,
          "&:hover": {
            color: "#038FAD",
            background: "none",
          },
          ".MuiTouchRipple-root": {
            display: "none",
          },
        },
        "&.Mui-selected": {
          border: `1px solid ${theme.palette.pagination.borderSelected}`,
          background: theme.palette.pagination.backgroundSelected,
        },
        "&.Mui-disabled": {
          opacity: 0.3,
        },
      },
      li: {
        "&:first-of-type .MuiPaginationItem-previousNext": {
          position: "relative",
          marginRight: theme.spacing(2),
          paddingLeft: 24,
          svg: {
            position: "absolute",
            left: 0,
            margin: 0,
          },
          "&:after": {
            content: '"Prev"',
          },
        },
        "&:last-of-type .MuiPaginationItem-previousNext": {
          position: "relative",
          marginLeft: theme.spacing(2),
          paddingRight: 24,
          svg: {
            position: "absolute",
            right: 0,
            margin: 0,
          },
          "&:before": {
            content: '"Next"',
          },
        },
      },
    },
    sortIcon: {
      height: 24,
      width: 24,
      marginLeft: theme.spacing(1),
      "& path": {
        fill: theme.palette.text.secondary,
      },
    },
    mobileItem: {
      background: theme.palette.table.background,
      border: `1px solid ${theme.palette.table.border}`,
      boxShadow: "0px 4px 40px rgba(255, 255, 255, 0.04)",
      borderRadius: 16,
      width: "100%",
      padding: 16,
      marginBottom: 16,
      position: "relative",
      overflow: "hidden",
      // "& > div": {
      //   transition: "height 4s linear 1s",
      // },
    },
    mobileItemWithChart: {
      paddingBottom: 0,
    },
    expabdedMobileItem: {
      maxHeight: 128,
      overflow: "hidden",
    },
    expandIcon: {
      position: "absolute",
      top: 16,
      right: 16,
    },
    mobileSortingRoot: {
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      overflow: "hidden",
      maxHeight: "100%",
      zIndex: 999,
      display: "flex",
      background: theme.palette.table.extended,
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      boxShadow: "0px -4px 40px rgba(0, 0, 0, 0.04)",
    },
    mobileSortingWrapper: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      maxWidth: 1440,
      marginLeft: "auto",
      marginRight: "auto",

      [theme.breakpoints.up("md")]: {
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
      },
    },
    mobileSortingList: {
      flexGrow: 1,
      overflowY: "auto",
    },
    mobileSortingOpenBtn: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    mobileSortingBtn: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      padding: "12px 16px",
      svg: {
        path: {
          fill: "currentColor",
        },
      },
    },
    mobileSortingCancelBtnWrap: {
      flexShrink: 0,
    },
  };
});

export default function TableContainer<
  TRow = unknown,
  TSortColumns extends string = string
>({
  className,
  data,
  page,
  rowKey,
  titles,
  dataCount,
  renderRow: customRenderRow,
  rowContent,
  expandedRowValues = {},
  onChangePage,
  withHead = true,
  loading = false,
  ignoreTableClass,
  stickers = [0, 34],
  onChangeSorting,
  useRouterPagination,
  pageSize: defaultPageSize,
  serverSideSorting,
  isFixedLayout,
  width,
  topHeader = 135,
  removeBorderSpacing = false,
  homePage,
  mobileItem = undefined,
  shouldExpanded = false,
  mobileBreakpoint = "sm",
  isFixedHeader = false,
  showMoreItem = true,
  showMobileSorting = false,
  gaEventCategory,
}: TableContainerProps<TRow, TSortColumns>) {
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(
    theme.breakpoints.down(mobileBreakpoint),
  );
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [sorting, setSorting] = useState<SortItem<TSortColumns>>(undefined);
  const [rowsToRenderState, setRowsToRenderState] = useState<boolean>(
    undefined,
  );
  const [expandedItem, setExpandedItem] = useState(null);
  const pageSize = defaultPageSize || PAGE_SIZE;
  const { height: pageHeight } = useWindowDimensions();
  const classes = useStyles({
    stickers,
    pageHeight,
    isFixedLayout,
    width,
    topHeader,
    removeBorderSpacing,
    isFixedHeader,
  });
  const [mobileSotringOpened, setMobileSortingOpened] = useState(false);

  const [fiveSeconds, setFiveSeconds] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFiveSeconds(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const onChangeSort = useCallback(
    (index) => {
      const titleData = titles[index];
      let sb, st;
      if (typeof titleData === "string") return;
      if (sorting?.column !== titleData.title) {
        sb = titleData.title;
        st = SortOrder.ASC;
      } else {
        sb = sorting?.column;
        st = sorting?.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
      }

      setSorting({
        column: sb,
        order: st,
      });

      if (typeof onChangeSorting === "function") {
        onChangeSorting({
          column: titleData.sortBy,
          order: st,
        });
      }
      setMobileSortingOpened(false);
    },
    [titles, sorting?.column, sorting?.order, onChangeSorting],
  );

  const rowsToRender = useMemo(() => {
    setRowsToRenderState(true);

    if (!data || !sorting?.column || serverSideSorting) return data;

    const titleData = titles.find(
      (title) => typeof title !== "string" && title.title === sorting?.column,
    );

    if (!titleData) return data;
    if (typeof titleData === "string") return data;

    const sortedData = data.sort((a, b) => {
      const isKeyPath = titleData.sortBy.includes(".");
      // TODO: somehow remove "as string"?
      const valueA = isKeyPath
        ? titleData.sortBy
            .split(".")
            .reduce((previous, current) => previous[current], a)
        : a[titleData.sortBy as string];
      const valueB = isKeyPath
        ? titleData.sortBy
            .split(".")
            .reduce((previous, current) => previous[current], b)
        : b[titleData.sortBy as string];

      if (valueA === valueB) return 0;

      const numberA = Number(valueA);
      const numberB = Number(valueB);
      const isNumbers = numberA && numberB;

      if (isNumbers) {
        if (sorting?.order === SortOrder.ASC) {
          return numberA - numberB;
        } else {
          return numberB - numberA;
        }
      } else {
        if (sorting?.order === SortOrder.ASC) {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueB > valueA ? 1 : -1;
        }
      }
    });
    return sortedData;
  }, [data, sorting?.column, sorting?.order, titles, serverSideSorting]);

  const { initialRenderRows, lazyRenderRowsBatches } = useMemo(() => {
    if (!rowsToRender) {
      return {};
    }
    const pageCount = page || 0;

    const initialRenderRows = [...rowsToRender].splice(
      pageCount * 100,
      LAZY_RENDER_BATCH,
    );

    const lazyRenderRows =
      rowsToRender.length > 20
        ? [...rowsToRender].slice(pageCount * 100 + LAZY_RENDER_BATCH)
        : [];
    const lazyRenderRowsBatches = [];

    if (lazyRenderRows.length >= 80) {
      lazyRenderRowsBatches.push([...lazyRenderRows].slice(0, 80));
    } else {
      for (
        let i = 0;
        i < Math.ceil(lazyRenderRows.length / LAZY_RENDER_BATCH);
        ++i
      ) {
        lazyRenderRowsBatches.push(
          lazyRenderRows.slice(
            i * LAZY_RENDER_BATCH,
            (i + 1) * LAZY_RENDER_BATCH,
          ),
        );
      }
    }

    setRowsToRenderState(false);
    return { initialRenderRows, lazyRenderRowsBatches };
  }, [rowsToRenderState, rowsToRender, page]);

  const renderHeaderCols = useCallback(() => {
    return titles?.map((title, index) => {
      const text = typeof title === "string" ? title : title?.title;
      const hasSorting =
        typeof title === "string" ? false : title?.sortBy !== undefined;
      const props = typeof title === "string" ? {} : title?.props || {};
      const styles = typeof title === "string" ? {} : title?.styles || {};
      const intervalIcon =
        typeof title === "string" ? false : title?.intervalIcon;
      const sortAfterFiveSeconds =
        typeof title === "string" ? false : title?.sortAfterFiveSeconds;

      const disableSort = sortAfterFiveSeconds && !fiveSeconds;

      const titleElement = (
        <Typography variant="caption" component="span">
          {text}
        </Typography>
      );
      let sortIcon;
      if (hasSorting) {
        if (sorting?.column !== text) {
          // sortIcon = <IconSorting />;
          sortIcon = null;
        } else if (sorting?.order === SortOrder.ASC) {
          sortIcon = <IconUp />;
        } else {
          sortIcon = <IconDown />;
        }
      }

      return (
        <TableCell
          key={`${text}${index}`}
          align={index === 1 ? "left" : "center"}
          style={{
            width: text === "#" ? 60 : props.width ?? "auto",
            padding: styles.padding,
          }}
          {...props}>
          {!hasSorting ? (
            titleElement
          ) : (
            <ButtonBase
              disableRipple
              onClick={() => {
                if (gaEventCategory) {
                  trackEvent(
                    {
                      action: GAEventAction.SortByPosition,
                      category: gaEventCategory,
                      label: GAEventLabel.SortByPosition,
                    },
                    isDownSm,
                  );
                }
                return onChangeSort(index);
              }}
              disabled={disableSort}>
              {titleElement}
              {intervalIcon && <IntervalIcon interval={intervalIcon} m={1} />}
              <Box className={classes.sortIcon}>{sortIcon}</Box>
            </ButtonBase>
          )}
        </TableCell>
      );
    });
  }, [
    titles,
    sorting?.column,
    sorting?.order,
    onChangeSort,
    fiveSeconds,
    isDownSm,
    gaEventCategory,
  ]);

  const renderMobileSorting = useCallback(() => {
    return titles?.map((title, index) => {
      const hasSorting =
        typeof title === "string" ? false : title?.sortBy !== undefined;
      if (!hasSorting) return null;
      const text = typeof title === "string" ? title : title?.title;
      const titleElement = (
        <Typography variant="body2" component="span">
          {text}
        </Typography>
      );
      let sortIcon;
      if (hasSorting) {
        if (sorting?.column !== text) {
          sortIcon = null;
        } else if (sorting?.order === SortOrder.ASC) {
          sortIcon = <IconUp />;
        } else {
          sortIcon = <IconDown />;
        }
      }

      return (
        <Box key={`${text}${index}`} display="flex">
          <ButtonBase
            className={classes.mobileSortingBtn}
            onClick={() => {
              onChangeSort(index);
              if (gaEventCategory) {
                trackEvent(
                  {
                    action: GAEventAction.SortByPosition,
                    category: gaEventCategory,
                    label: GAEventLabel.SortByPosition,
                  },
                  isDownSm,
                );
              }
            }}>
            {titleElement}
            <Box className={classes.sortIcon}>{sortIcon}</Box>
          </ButtonBase>
        </Box>
      );
    });
  }, [
    titles,
    sorting?.column,
    sorting?.order,
    onChangeSort,
    isDownSm,
    gaEventCategory,
  ]);

  // page ? pageSize * page + index : index,
  const renderRow = useCallback(
    (row, id) => {
      return customRenderRow ? (
        customRenderRow(row, id, classes.tableBodyRow, expandedRowValues)
      ) : (
        <TableRow
          classes={{ root: cx("_table-row", classes.tableBodyRow) }}
          key={`${row[rowKey]}_${id}`}>
          {rowContent(row, id)}
        </TableRow>
      );
    },
    [rowContent, expandedRowValues, customRenderRow],
  );

  const handleExpandedItem = (index) => {
    if (typeof expandedItem === "number") {
      setExpandedItem(null);
    } else {
      setExpandedItem(index);
    }
  };

  return !isMobile || !(typeof mobileItem !== "undefined") ? (
    <div className={className}>
      <Grid item container xs={12} className={classes.gridContainer}>
        <TableCoreContainer classes={{ root: classes.tableContainer }}>
          <Table
            aria-label="assets table"
            stickyHeader
            classes={{ root: classes.tableRoot }}>
            {withHead && (
              <TableHead>
                <TableRow
                  classes={{
                    root: cx(classes.tableHeadRow, classes.headerRow),
                  }}>
                  {renderHeaderCols()}
                </TableRow>
              </TableHead>
            )}
            <TableBody
              classes={
                ignoreTableClass ? undefined : { root: classes.tableBody }
              }>
              {loading &&
                new Array(pageSize).fill(null).map((v, id) => (
                  <TableRow
                    classes={{ root: classes.tableRowPlaceholder }}
                    key={id}>
                    <TableCell align="left" colSpan={titles.length}>
                      <Skeleton variant="text" height={24} />
                    </TableCell>
                  </TableRow>
                ))}
              {initialRenderRows &&
                initialRenderRows.map((row, index) =>
                  renderRow(row, page ? pageSize * page + index : index),
                )}
              {showMoreItem &&
                lazyRenderRowsBatches &&
                lazyRenderRowsBatches.map((lazyRenderRows, batchId) => (
                  <LazyRender rootMargin="600px 0px 600px 0px" key={batchId}>
                    {lazyRenderRows.map((row, index) =>
                      renderRow(
                        row,
                        page
                          ? pageSize * page +
                              ((batchId + 1) * LAZY_RENDER_BATCH + index)
                          : (batchId + 1) * LAZY_RENDER_BATCH + index,
                      ),
                    )}
                  </LazyRender>
                ))}
            </TableBody>
          </Table>
        </TableCoreContainer>
      </Grid>
      {page !== undefined && onChangePage && !homePage && (
        <Box className={classes.paginationWrap} pt={2}>
          <Pagination
            page={page + 1}
            count={getPaginationCount(dataCount, pageSize)}
            onChange={(e, page) => {
              if (loading === false) {
                if (window) {
                  window.scrollTo(0, 0);
                }
                onChangePage(useRouterPagination ? page : page - 1);
                trackEvent({
                  action: `${page}`,
                  category: GAEventCategory.Pagination,
                  label: `${page}`,
                });
              }
            }}
            siblingCount={0}
            renderItem={(itemProps) => (
              <PaginationItem
                component={useRouterPagination ? PageLink : ButtonBase}
                {...{ ...itemProps, to: itemProps.page }}
              />
            )}
            className={classes.pagination}
          />
          <Typography variant="body2" color="textSecondary" mt={1}>
            {labelDisplayedRows(dataCount, page, pageSize)}
          </Typography>
        </Box>
      )}
    </div>
  ) : (
    <Box className={cx(classes.mobileRoot, className)}>
      {showMobileSorting ? (
        <>
          <Box mb={2}>
            <ButtonBase
              className={classes.mobileSortingOpenBtn}
              disableRipple
              onClick={() =>
                setMobileSortingOpened((prev) => {
                  if (gaEventCategory && !prev) {
                    trackEvent(
                      {
                        action: GAEventAction.OpenSortedPopover,
                        category: gaEventCategory,
                        label: GAEventLabel.OpenSortedPopover,
                      },
                      isDownSm,
                    );
                  }
                  return !prev;
                })
              }>
              <Typography
                variant="body1"
                component="span"
                display="flex"
                justifyContent="space-between">
                Sorted
              </Typography>
              <KeyboardArrowDownIcon />
            </ButtonBase>
          </Box>
          <Slide direction="up" in={mobileSotringOpened}>
            <Box className={classes.mobileSortingRoot}>
              <Box className={classes.mobileSortingWrapper}>
                <Box className={classes.mobileSortingList}>
                  {renderMobileSorting()}
                </Box>
                <Box
                  px={2}
                  py={5}
                  className={classes.mobileSortingCancelBtnWrap}>
                  <MainButton
                    title="Cancel"
                    fullWidth
                    variant="secondary"
                    onClick={() => setMobileSortingOpened(false)}
                  />
                </Box>
              </Box>
            </Box>
          </Slide>
        </>
      ) : null}
      {initialRenderRows &&
        initialRenderRows.map((row, index) => (
          <Box
            className={cx("_table-row", classes.mobileItem, {
              [classes.mobileItemWithChart]: homePage,
            })}
            onClick={
              shouldExpanded ? () => handleExpandedItem(index) : undefined
            }
            key={row[rowKey]}>
            <Box
              height={40}
              className={cx("table-mobile-toggle", classes.expandIcon)}>
              {shouldExpanded &&
                (expandedItem === index ? <CloseIcon /> : <PlusIcon />)}
            </Box>
            {mobileItem(
              row,
              page ? pageSize * page + index : index,
              expandedItem === index,
            )}
          </Box>
        ))}
      {showMoreItem &&
        lazyRenderRowsBatches &&
        lazyRenderRowsBatches.map((lazyRenderRows, batchId) => (
          <LazyRender rootMargin="600px 0px 600px 0px" key={batchId}>
            {lazyRenderRows.map((row, index) => (
              <Box
                className={cx(classes.mobileItem, {
                  [classes.mobileItemWithChart]: homePage,
                })}
                onClick={
                  shouldExpanded ? () => handleExpandedItem(index) : undefined
                }
                key={row[rowKey]}>
                <Box
                  height={40}
                  className={cx("table-mobile-toggle", classes.expandIcon)}>
                  {shouldExpanded &&
                    (expandedItem === index ? <CloseIcon /> : <PlusIcon />)}
                </Box>
                {mobileItem(
                  row,
                  page
                    ? pageSize * page +
                        ((batchId + 1) * LAZY_RENDER_BATCH + index)
                    : (batchId + 1) * LAZY_RENDER_BATCH + index,
                  expandedItem === index,
                )}
              </Box>
            ))}
          </LazyRender>
        ))}
      {page !== undefined && onChangePage && !homePage && (
        <Box className={classes.paginationWrap} pt={1}>
          <Pagination
            page={page + 1}
            count={getPaginationCount(dataCount, pageSize)}
            onChange={(e, page) => {
              if (loading === false) {
                if (window) {
                  window.scrollTo(0, 0);
                }
                onChangePage(useRouterPagination ? page : page - 1);
                trackEvent(
                  {
                    action: `${page}`,
                    category: GAEventCategory.Pagination,
                    label: `${page}`,
                  },
                  isDownSm,
                );
              }
            }}
            siblingCount={0}
            renderItem={(itemProps) => (
              <PaginationItem
                component={useRouterPagination ? PageLink : ButtonBase}
                {...{ ...itemProps, to: itemProps.page }}
              />
            )}
            className={classes.pagination}
          />
          <Typography variant="body2" color="textSecondary" mt={1}>
            {labelDisplayedRows(dataCount, page, pageSize)}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

function PageLink({ to, ...props }) {
  const router = useRouter();
  return (
    <NextLink
      href={{
        pathname: router.pathname,
        query: { ...router.query, page: to },
      }}
      shallow={true}
      passHref={true}>
      <a {...props} />
    </NextLink>
  );
}

function getPaginationCount(dataCount, pageSize) {
  return dataCount < pageSize ? 1 : Math.ceil(dataCount / pageSize);
}

function getLabelDisplayedRowsTo(dataCount, page, pageSize) {
  if (dataCount === -1) return (page + 1) * pageSize;
  return pageSize === -1
    ? dataCount
    : Math.min(dataCount, (page + 1) * pageSize);
}

function labelDisplayedRows(dataCount, page, pageSize) {
  const paginationCount = getPaginationCount(dataCount, pageSize);
  const from = paginationCount === 0 ? 0 : page * pageSize + 1;
  const to = getLabelDisplayedRowsTo(dataCount, page, pageSize);
  return `${from}-${to} of ${
    dataCount !== -1 ? `${dataCount} rows` : `more than ${to}`
  }`;
}
