import {
  Typography,
  Box,
  BoxProps,
  TypographyPropsVariantOverrides,
} from "@mui/material";
import { DistributiveOmit } from "@mui/types";
import { makeStyles } from "@lib/themes";
import { ITheme } from "@lib/themes/types";
import { ReactNode } from "react";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";

interface InfoBlockProps extends DistributiveOmit<BoxProps, "title"> {
  title: string | ReactNode;
  value: string | number | ReactNode;
  titleAccessory?: ReactNode;
  titleVariant?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
}

const useStyles = makeStyles((theme: ITheme, props) => ({
  title: {
    color: theme.palette.text.secondary,
    // marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: props.isCaption && 12,
      fontWeight: props.isCaption && 600,
      marginBottom: props.isCaption && 4,
    },
  },
}));

const InfoBlock = ({
  title,
  value,
  titleAccessory,
  titleVariant = "body2",
  ...props
}: InfoBlockProps) => {
  const classes = useStyles({
    isCaption: titleVariant === "caption",
  });

  return (
    <Box {...props}>
      <Box display="flex">
        {typeof title === "string" ? (
          <>
            <Typography variant={titleVariant} className={classes.title}>
              {title}
            </Typography>
            {titleAccessory}
          </>
        ) : (
          title
        )}
      </Box>
      {typeof value === "string" || typeof value === "number" ? (
        <Typography variant="body1" color="textPrimary">
          {value}
        </Typography>
      ) : (
        value
      )}
    </Box>
  );
};

export default InfoBlock;
